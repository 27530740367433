<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-container class="d-flex flex-column ">
			<v-card-title
				class="secondary--text font-weight-bold flex-row justify-center"
			>
				<span> {{ t("title") }}</span>
				<BtnHelp small class="mr-n2" />
			</v-card-title>
			<v-card-subtitle class="flex text-center font-weight-medium">
				{{ t("subtitle") }}
			</v-card-subtitle>
			<v-form ref="form">
				<v-row dense class="justify-center my-4">
					<v-col cols="12">
						<TextField
							:disabled="inputDisable"
							@focus="openForm()"
							:icon-append="flightMessageClass.icon"
							:icon-append-color="flightMessageClass.iconColor"
							prepend-inner-icon="airplane_ticket"
							:value="flightNumber"
							:message-class="flightMessageClass.class"
							:messages="flightNumberMessage"
							:title="`${t('form.flightNumber')}*`"
						/>
					</v-col>
				</v-row>
			</v-form>
			<CardDateTime
        @click.native="openForm()"
        v-ripple="{ center: true }"
				:date="checkinModel.flightDatetime"
				class="align-self-center cursor-pointer"
			/>
		</v-container>

		<v-container class="mb-3">
			<v-card-text
				v-if="isArrival"
				class="mx-n2 mb-1 font-weight-bold flex text-center "
			>
				{{ t("informationArrival") }}
			</v-card-text>
			<LayoutBtn
				:edition-locked="inputDisable"
				:edit="$t('global.btnEdit')"
				:confirm="$t('global.btnConfirm')"
				:disabledConfirm="
					!isValid || this.checkinModel.flightValidationStatus === 'none'
				"
				@click-edit="openForm()"
				@click-confirm="confirm()"
			/>
		</v-container>
	</div>
</template>

<script>
import TextField from "@/components/field/TextField";

import CardDateTime from "@/components/card/CardDateTimeFlight";
import BtnHelp from "@/components/btn/BtnHelp";
import LayoutBtn from "@/layouts/LayoutBtn";
import { mapFields } from "@/helpers/mapFields";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
	components: {
		CardDateTime,
		TextField,

		BtnHelp,
		LayoutBtn
	},
	data: () => ({
		isValid: false
	}),
	computed: {
		...mapFields({
			fields: ["flightDatetime"],
			base: "checkinModel",
			mutation: "SET_UPDATE_MODEL",
			module: "service"
		}),

		...mapGetters("service", ["getBasePath", "isArrival", "inputDisable"]),
		...mapState("service", ["checkinModel"]),
		isFlightValid: vm => vm.flightValidStatus != "none",
		flightValidStatus: vm => vm.checkinModel.flightValidationStatus,
		flightNumberMessage: vm =>
			vm.isFlightValid
				? vm.flightValidStatus == "api"
					? vm.t("form.flightNumberMessageValidApi")
					: vm.t("form.flightNumberMessageValidUser")
				: vm.t("form.flightNumberMessageInvalid"),
		flightMessageClass: vm => ({
			class: vm.isFlightValid ? "" : "error--text",
			icon: vm.isFlightValid ? undefined : "report_problem",
			iconColor: vm.isFlightValid ? "" : "error"
		}),
		flightNumber: vm => vm.checkinModel.flightNumber || ""
	},
	watch: {
		checkinModel() {
			this.init();
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		...mapActions("service", ["_updateService"]),
		init() {
			setTimeout(() => (this.isValid = this.$refs.form.validate()), 500);
		},
		confirm() {
			this._updateService({
				flightValidationStatus: this.checkinModel
					.flightValidationStatus,
				flightValidationData: this.checkinModel.flightValidationData,
				flightNumber: this.checkinModel.flightNumber,
				flightDatetime: this.checkinModel.flightDatetime,
				pickupDatetime: this.checkinModel.pickupDatetime
			}).then(() =>
				this.isArrival
					? this.$router.push(
							this.getBasePath("/reservation/passenger")
					  )
					: this.$router.push(
							this.getBasePath("/reservation/pschedule")
					  )
			);
		},
		openForm() {
			if (this.inputDisable)
				return (this.$root.$children[0].locked = true);
			this.$router.push(this.getBasePath("/form/flight"));
		},
		t(key, options = {}) {
			return this.$t(`section.flight.${key}`, options);
		}
	}
};
</script>
